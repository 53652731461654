import { IoAddCircle, IoRemoveCircle } from "solid-icons/io";
import {
  Component,
  For,
  Match,
  Show,
  Switch,
  createEffect,
  createMemo,
  createSignal,
  untrack,
} from "solid-js";
import { SetStoreFunction, produce } from "solid-js/store";
import {
  AddProductToOrderFn,
  Category,
  Course,
  OrderItem,
  OrderItemsMap,
  RemoveProductFromOrderFn,
  Tag,
  TagSelectionMode,
  ViewMode,
  makeOrderItemDescription,
  makeOrderItemId,
  type Product as ProductT,
} from "~/common-types";
import ButtonBar from "~/components/ButtonBar";
import IconButton from "~/components/IconButton";
import Search from "~/components/Search";
import ToggleGroup from "~/components/ToggleGroup";
import Product from "~/components/table_name/Product";
import ProductDialog from "~/components/table_name/ProductDialog";
import styles from "./PickProducts.module.css";

const GridView: Component<{
  products: ProductT[];
  orderItemsMap: { [id: string]: OrderItem };
  addProductToOrder: AddProductToOrderFn;
  removeProductFromOrder: RemoveProductFromOrderFn;
  selectedCourse: string | undefined;
  productVariant?: "left";
}> = (props) => {
  return (
    <section class={styles.products}>
      <For each={props.products} fallback={<div>No items</div>}>
        {(product) => (
          <Product
            product={product}
            count={
              props.orderItemsMap[
                makeOrderItemId({
                  id: product.id,
                  name: product.name,
                  short_name: product.short_name,
                  vessel:
                    product.vessels_products.find((vp) => vp.is_default)
                      ?.vessels?.name ?? "",
                  variants: [],
                  otherSpecs: undefined,
                  course: props.selectedCourse,
                  price:
                    product.vessels_products.find((vp) => vp.is_default)
                      ?.price ?? 0,
                })
              ]?.quantity ?? 0
            }
            onAddToOrder={(params) => {
              props.addProductToOrder({
                ...params,
                course: props.selectedCourse,
              });
            }}
            onRemoveFromOrder={props.removeProductFromOrder}
            variant={props.productVariant}
          />
        )}
      </For>
    </section>
  );
};

const ListViewProduct: Component<{
  product: ProductT;
  quantity?: number;
  onRemoveProductFromOrder: RemoveProductFromOrderFn;
  onAddProductToOrder: AddProductToOrderFn;
  selectedCourse: string | undefined;
  productVariant?: "left";
}> = (props) => {
  const [showSpecs, setShowSpecs] = createSignal(false);
  const defaultVessel = createMemo(() =>
    props.product.vessels_products.find((vp) => vp.is_default),
  );

  return (
    <>
      <ProductDialog
        showSpecs={showSpecs()}
        setShowSpecs={setShowSpecs}
        product={props.product}
        onAdd={(params) => {
          props.onAddProductToOrder({
            ...params,
            course: props.selectedCourse,
          });
          setShowSpecs(false);
        }}
        variant={props.productVariant}
      />
      <article class={styles.dish} onClick={() => setShowSpecs(true)}>
        <div class={`${styles.quantity} ${!props.quantity ? styles.hide : ""}`}>
          {props.quantity}
        </div>
        <div class={styles.name}>
          {props.product.short_name
            ? props.product.short_name
            : props.product.name}
        </div>
        <div>
          {defaultVessel()!.price!.toLocaleString("it-IT", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}{" "}
          €
        </div>
        <div class={styles.actions}>
          <Show when={props.quantity && props.quantity > 0}>
            <IconButton
              onClick={() =>
                props.onRemoveProductFromOrder({
                  id: props.product.id,
                  name: props.product.name,
                  short_name: props.product.short_name,
                  vessel: defaultVessel()?.vessels?.name ?? "",
                  variants: [],
                  course: props.selectedCourse,
                  otherSpecs: undefined,
                  price: defaultVessel()!.price!,
                })
              }
            >
              <IoRemoveCircle />
            </IconButton>
          </Show>
          <IconButton
            onClick={() => {
              props.onAddProductToOrder({
                id: props.product.id,
                name: props.product.name,
                short_name: props.product.short_name,
                vessel: defaultVessel()?.vessels?.name ?? "",
                variants: [],
                course: props.selectedCourse,
                otherSpecs: undefined,
                price: defaultVessel()!.price!,
              });
            }}
          >
            <IoAddCircle />
          </IconButton>
        </div>
      </article>
    </>
  );
};

const ListView: Component<{
  products: ProductT[];
  orderItemsMap: { [id: string]: OrderItem };
  onAddProductToOrder: AddProductToOrderFn;
  onRemoveProductFromOrder: RemoveProductFromOrderFn;
  selectedCourse: string | undefined;
  productVariant?: "left";
}> = (props) => {
  return (
    <section class={styles.kitchen}>
      <For each={props.products} fallback={<div>No products</div>}>
        {(product) => (
          <ListViewProduct
            product={product}
            quantity={
              props.orderItemsMap[
                makeOrderItemId({
                  id: product.id,
                  name: product.name,
                  short_name: product.short_name,
                  vessel:
                    product.vessels_products.find((vp) => vp.is_default)
                      ?.vessels?.name ?? "",
                  variants: [],
                  otherSpecs: undefined,
                  course: props.selectedCourse,
                  price:
                    product.vessels_products.find((vp) => vp.is_default)
                      ?.price ?? 0,
                })
              ]?.quantity ?? 0
            }
            onAddProductToOrder={props.onAddProductToOrder}
            onRemoveProductFromOrder={props.onRemoveProductFromOrder}
            selectedCourse={props.selectedCourse}
            productVariant={props.productVariant}
          />
        )}
      </For>
    </section>
  );
};

/* Main Component */
const PickProducts: Component<{
  tableName: string;
  products: () => ProductT[] | undefined;
  courses: () => Course[] | undefined;
  orderItemsMap: OrderItemsMap;
  setOrderItemsMap: (fn: (state: OrderItemsMap) => OrderItemsMap) => void;
  productVariant?: "left";
}> = (props) => {
  /* State */
  const [selectedCourseId, setSelectedCourseId] = createSignal<
    Course["id"] | undefined
  >(undefined);

  /* Computed */
  const selectedCourse = createMemo(
    () => props.courses()?.find((c) => c.id === selectedCourseId())?.name,
  );

  createEffect(() => {
    const courseID = untrack(selectedCourseId);
    if (
      categories() == null ||
      !categories()![selectedCategoryIndex()].courses_mode
    ) {
      setSelectedCourseId(undefined);
    } else {
      if (props.courses()?.length && courseID == null) {
        setSelectedCourseId(props.courses()?.[0]?.id);
      } else {
        setSelectedCourseId(undefined);
      }
    }
  });

  const categories = createMemo(() => {
    const categories =
      props.products()?.reduce((categories, p) => {
        p.categories_products.forEach((cp) => {
          const id = cp.categories?.id;
          if (id && !categories.has(id)) {
            categories.set(id, cp.categories as Category);
          }
        });
        return categories;
      }, new Map<string, Category>()) ?? new Map<string, Category>();
    return Array.from(categories.values()).sort(
      (a, b) => a.order_id - b.order_id,
    );
  });

  const tagsByCategoryId = createMemo(() => {
    const tagsByCategory = props.products()?.reduce<{
      [category_id: string]: {
        selectionMode: TagSelectionMode;
        tags: { [tag_id: string]: Tag };
      };
    }>((tagsByCategory, p) => {
      p.categories_products.forEach((cp) => {
        const category_id = cp.categories?.id;
        if (category_id) {
          if (tagsByCategory[category_id] == null) {
            tagsByCategory[category_id] = {
              selectionMode: cp.categories!
                .tag_selection_mode as TagSelectionMode,
              tags: {},
            };
          }

          tagsByCategory[category_id].tags = p.tags_products.reduce<{
            [tag_id: string]: Tag;
          }>((tags, tp) => {
            const tag_id = tp.tags?.id;
            if (tag_id) {
              tags[tag_id] = tp.tags as Tag;
            }
            return tags;
          }, tagsByCategory[category_id].tags);
        }
      });
      return tagsByCategory;
    }, {});
    return tagsByCategory ?? {};
  });

  const categoryButtons = () =>
    categories()?.map((category, index) => ({
      name: category.name,
      text: category.name,
      selected: index === selectedCategoryIndex(),
    }));

  const viewConfiguration = () => {
    const categoryIndex = selectedCategoryIndex();
    const selectedCategory =
      categoryIndex != null ? categories()?.[categoryIndex] : undefined;
    const showCourses =
      selectedCategory?.courses_mode &&
      props.courses()?.length &&
      props.courses()!.length > 0;
    switch (selectedCategory?.products_visualization_mode) {
      case 0: {
        return {
          tag: ViewMode.Grid,
          showSearchBar: selectedCategory.show_searchbar,
          showCourses,
        };
      }
      case 1: {
        return {
          tag: ViewMode.List,
          showSearchBar: selectedCategory.show_searchbar,
          showCourses,
        };
      }
      default: {
        return {
          tag: ViewMode.List,
          showSearchBar: false,
          showCourses,
        };
      }
    }
  };

  const tagButtons = () => {
    function createButton(tag: Tag) {
      return {
        id: tag.id,
        text: tag.name,
        selected: selectedTagIds()?.includes(tag.id) ?? false,
      };
    }

    const categoryIndex = selectedCategoryIndex();
    const selectedCategory =
      categoryIndex != null ? categories()?.[categoryIndex] : undefined;
    if (selectedCategory != null && selectedCategory.show_tags) {
      const tags = tagsByCategoryId()[selectedCategory!.id]?.tags;
      return Object.values(tags)
        .sort((a, b) => (a.order_id ?? 0) - (b.order_id ?? 0))
        .map(createButton);
    }
    return [];
  };

  const filteredProducts = () => {
    const selectedCategory = categories()?.[selectedCategoryIndex()];
    const productsInCategory = props.products()?.filter((product) => {
      if (selectedTagIds()?.length ?? 0 > 0) {
        if (selectedCategory.show_tags === false) {
          return true;
        }
        let productInTag = false;
        if (
          selectedCategory.tag_selection_mode === TagSelectionMode.MultiOR ||
          selectedCategory.tag_selection_mode === TagSelectionMode.Single
        ) {
          for (const tp in product.tags_products) {
            const tag = product.tags_products[tp].tags;
            if (tag && selectedTagIds()?.includes(tag.id)) {
              productInTag = true;
              break;
            }
          }
        } else {
          // TagSelectionMode.MultiAND
          // return true only if all selected tags are present in the product
          productInTag = true;
          for (const tagId of selectedTagIds() ?? []) {
            const tag = product.tags_products.find(
              (tp) => tp.tags?.id === tagId,
            );
            if (!tag) {
              productInTag = false;
              break;
            }
          }
        }
        if (!productInTag) {
          return false;
        }
      }
      return (
        product.categories_products.some(
          (cp) => cp.categories?.id === selectedCategory?.id,
        ) &&
        (searchFilter() === "" ||
          product.name.toLowerCase().includes(searchFilter().toLowerCase()))
      );
    });

    if (productsInCategory && productsInCategory.length > 0) {
      const orderIds = productsInCategory.map(
        (product) =>
          product.categories_products.find(
            (cp) => cp.categories?.id === selectedCategory?.id,
          )?.order_id,
      );
      if (orderIds.some((order_id) => order_id !== null)) {
        return productsInCategory.sort((a, b) => {
          const orderA = a.categories_products.find(
            (cp) => cp.categories?.id === selectedCategory?.id,
          )?.order_id;
          const orderB = b.categories_products.find(
            (cp) => cp.categories?.id === selectedCategory?.id,
          )?.order_id;
          if (orderA === null) return 1;
          if (orderB === null) return -1;
          return (orderA || 0) - (orderB || 0); // Usa 0 come fallback se orderA o orderB sono null
        });
      }
    }
    return productsInCategory;
  };

  /* State */
  const [selectedCategoryIndex, setSelectedCategoryIndex] =
    createSignal<number>(0);
  const [selectedTagIds, setSelectedTagIds] = createSignal<
    string[] | undefined
  >();
  const [searchFilter, setSearchFilter] = createSignal<string>("");

  /* Actions */
  const addProductToOrder: AddProductToOrderFn = (params) => {
    const description = makeOrderItemDescription(params);
    const uuid = makeOrderItemId(params);
    const selectedCategory =
      untrack(categories)?.[untrack(selectedCategoryIndex)];
    const selectedCategoryHasCourses = selectedCategory?.courses_mode;
    const _selectedCourseId = selectedCategoryHasCourses
      ? untrack(selectedCourseId)
      : undefined;

    props.setOrderItemsMap(
      produce<OrderItemsMap>((orderItems) => {
        if (orderItems[uuid] != null) {
          orderItems[uuid] = {
            ...orderItems[uuid],
            quantity: orderItems[uuid].quantity + (params.quantity ?? 1),
          };
        } else {
          orderItems[uuid] = {
            id: uuid,
            product_id: params.id,
            description: description,
            price: params.price,
            quantity: params.quantity ?? 1,
            payed_quantity: 0,
            course_id: _selectedCourseId,
          };
        }
      }),
    );
  };

  const removeProductFromOrder: RemoveProductFromOrderFn = (params) => {
    const id = makeOrderItemId(params);

    props.setOrderItemsMap(
      produce<OrderItemsMap>((orderItems) => {
        if (orderItems[id] != null) {
          if (orderItems[id].quantity > 1) {
            orderItems[id] = {
              ...orderItems[id],
              quantity: orderItems[id].quantity - 1,
            };
          } else {
            delete orderItems[id];
          }
        }
      }),
    );
  };

  function selectTag(tagIndex: number) {
    const category = untrack(categories)?.[selectedCategoryIndex()];
    const tag = untrack(tagButtons)?.[tagIndex];
    if (category != null) {
      switch (category.tag_selection_mode as TagSelectionMode) {
        case TagSelectionMode.Single: {
          if (selectedTagIds()?.includes(tag.id)) {
            setSelectedTagIds(undefined);
          } else {
            setSelectedTagIds([tag.id]);
          }
          break;
        }
        case TagSelectionMode.MultiOR:
        case TagSelectionMode.MultiAND: {
          setSelectedTagIds((currentTagIds) => {
            if (currentTagIds == null) {
              return [tag.id];
            }
            if (currentTagIds.includes(tag.id)) {
              const newValue = currentTagIds.filter((tId) => tId !== tag.id);
              return newValue.length === 0 ? undefined : newValue;
            } else {
              return [...currentTagIds, tag.id];
            }
          });
          break;
        }
      }
    }
  }

  return (
    <main class={styles.productsContainer}>
      <section class={styles.tags}>
        <ButtonBar
          class={styles.tagsButtonBar}
          buttons={categoryButtons() ?? []}
          onClick={(index) => {
            setSelectedCategoryIndex(index);
            setSelectedTagIds(undefined);
            setSearchFilter("");
          }}
        />
      </section>
      <Show when={tagButtons().length > 0}>
        <section class={styles.subTags}>
          <ButtonBar buttons={tagButtons()} onClick={selectTag} wrap={true} />
        </section>
      </Show>
      <Show when={viewConfiguration().showSearchBar}>
        <section class={styles.searchBar}>
          <Search value={searchFilter()} onInput={setSearchFilter} />
        </section>
      </Show>
      <Show when={viewConfiguration().showCourses}>
        <section class={styles.courses}>
          <ToggleGroup
            items={props.courses() ?? []}
            selectedItem={selectedCourseId()}
            onItemSelected={setSelectedCourseId}
          />
        </section>
      </Show>
      <Switch>
        <Match when={viewConfiguration().tag === ViewMode.Grid}>
          <GridView
            products={filteredProducts() || []}
            orderItemsMap={props.orderItemsMap}
            addProductToOrder={addProductToOrder}
            removeProductFromOrder={removeProductFromOrder}
            selectedCourse={selectedCourse()}
            productVariant={props.productVariant}
          />
        </Match>
        <Match when={viewConfiguration().tag === ViewMode.List}>
          <ListView
            products={filteredProducts() || []}
            orderItemsMap={props.orderItemsMap}
            onAddProductToOrder={addProductToOrder}
            onRemoveProductFromOrder={removeProductFromOrder}
            selectedCourse={selectedCourse()}
            productVariant={props.productVariant}
          />
        </Match>
      </Switch>
    </main>
  );
};

export default PickProducts;
