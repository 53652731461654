import { action, useAction, useSubmission } from "@solidjs/router";
import { DateTime } from "luxon";
import { BsCashCoin } from "solid-icons/bs";
import { IoAddCircle, IoRemoveCircle } from "solid-icons/io";
import {
  Component,
  For,
  Match,
  Show,
  Switch,
  createSignal,
  createEffect,
} from "solid-js";
import { SetStoreFunction, createStore } from "solid-js/store";
import {
  OrderItem,
  OrderItemsMap,
  Result,
  totalFromOrders,
  type Order as OrderT,
} from "~/common-types";
import Button from "~/components/Button";
import IconButton from "~/components/IconButton";
import { newOrder, updateOrderProduct } from "~/server/orders";
import AlertDialog from "./AlertDialog";
import styles from "./Bill.module.css";
import { showToast } from "./Toast";
import { reconcile } from "solid-js/store";

type BillOrderItem = {
  id: string;
  product_id: string;
  description: string | null;
  quantity: number;
  price: number;
};

type BillOrder = {
  order_id: string;
  created_at: string;
  items: BillOrderItem[];
};

const OrderItemComponent: Component<{
  item: BillOrderItem;
  initialQuantity: number;
  onQuantityChanged: (newQuantity: number) => void;
}> = (props) => {
  // Funzione di utilità per dividere la descrizione in parti
  const renderDescription = (description: string | null) => {
    if (!description) return null; // Gestisci il caso in cui description sia null
    const parts = description.split("\n");
    return (
      <div class={styles.descriptionContainer}>
        <div class={styles.inlineContainer}>
          {parts.slice(0, 2).map((part, index) => {
            let className = index === 0 ? styles.namePart : styles.formatPart;
            return <div class={className}>{part}</div>;
          })}
        </div>
        {parts.slice(2).map((part, index) => {
          let className =
            index === 0 ? styles.specificationsPart : styles.commentPart;
          return <div class={className}>{part}</div>;
        })}
      </div>
    );
  };

  return (
    <div
      classList={{
        [styles.orderItem]: true,
        [styles.orderItemModified]:
          props.item.quantity !== props.initialQuantity,
      }}
    >
      <div class={styles.quantity}>{props.item.quantity}</div>
      <div
        classList={{
          [styles.name]: true,
          [styles.strike]: props.item.quantity === 0,
        }}
      >
        {renderDescription(props.item.description ?? "")}
      </div>
      <div class={styles.priceBold}>
        {(props.item.price * props.item.quantity).toFixed(2).replace(".", ",")}{" "}
        €
      </div>
      <div class={styles.actions}>
        <IconButton
          label="reduce"
          disabled={props.item.quantity == 0}
          onClick={() => {
            props.onQuantityChanged(props.item.quantity - 1);
          }}
        >
          <IoRemoveCircle />
        </IconButton>
        <IconButton
          label="add"
          onClick={() => {
            props.onQuantityChanged(props.item.quantity + 1);
          }}
        >
          <IoAddCircle />
        </IconButton>
      </div>
    </div>
  );
};

export type BillProps = {
  table: string;
  orders: OrderT[];
  afterVariation?: () => void;
  afterVariationInvalidation?: unknown[];
  showHeader?: boolean;
  newOrder?: {
    orderItemsMap: OrderItemsMap;
    setOrderItemsMap: SetStoreFunction<OrderItemsMap>;
  };
};

const Bill: Component<BillProps> = (props) => {
  const showHeader = props.showHeader ?? true;

  /* State */
  const [showConfirmPayment, setShowConfirmPayment] = createSignal(false);

  /* Computed */
  const total = () => {
    return totalFromOrders(props.orders);
  };

  const orderItemsInitialQuantity = () =>
    props.orders.reduce<{ [id: string]: number }>((acc, order) => {
      for (const orderItem of order.items) {
        acc[orderItem.id] = orderItem.quantity - orderItem.payed_quantity;
      }
      return acc;
    }, {}) ?? {};

  const orderItemsByOrderId = () =>
    props.orders.reduce<{ [id: string]: OrderItem }>((acc, order) => {
      for (const orderItem of order.items) {
        acc[orderItem.id] = {
          id: orderItem.id,
          product_id: orderItem.product_id,
          description: orderItem.description!,
          price: orderItem.price,
          quantity: orderItem.quantity,
          payed_quantity: orderItem.payed_quantity,
        };
      }
      return acc;
    }, {}) ?? {};

  const [billOrder, setBillOrder] = createStore<BillOrder[]>([]);
  createEffect(() => {
    setBillOrder(
      props.orders.reduce<BillOrder[]>((acc, order) => {
        acc.push({
          order_id: order.order_id,
          created_at: order.created_at,
          items: order.items.map((item) => ({
            id: item.id,
            product_id: item.product_id,
            description: item.description,
            quantity: item.quantity - item.payed_quantity,
            price: item.price,
          })),
        });

        return acc;
      }, []),
    );
  });

  const orderItemsAreModified = () => {
    const hasNewOrder =
      props.newOrder != null &&
      Object.values(props.newOrder.orderItemsMap).length > 0;
    return (
      hasNewOrder ||
      billOrder.some((order) => {
        return order.items.some((item) => {
          return item.quantity !== orderItemsInitialQuantity()[item.id];
        });
      })
    );
  };

  /* Actions */
  const changeOrdersAction = action(async () => {
    const newOrderItemsPayed: { id: string; quantity: number }[] = [];
    const newOrderItemsToAddToOrder: OrderItem[] = props.newOrder
      ? Object.values(props.newOrder.orderItemsMap)
      : [];

    for (const order of billOrder) {
      for (const orderItem of order.items) {
        const initialQuantity = orderItemsInitialQuantity()[orderItem.id];
        const originalOrderItem = orderItemsByOrderId()[orderItem.id];
        if (orderItem.quantity < initialQuantity) {
          newOrderItemsPayed.push({
            id: orderItem.id,
            quantity: originalOrderItem.quantity - orderItem.quantity,
          });
        } else if (orderItem.quantity > initialQuantity) {
          newOrderItemsToAddToOrder.push({
            id: orderItem.id,
            product_id: orderItem.product_id,
            description: orderItem.description!,
            price: orderItem.price,
            quantity: orderItem.quantity - initialQuantity,
            payed_quantity: 0,
          });
        }
      }
    }

    let success = true;
    if (newOrderItemsPayed.length) {
      const result = await updateOrderProduct(newOrderItemsPayed);
      if (result?.error) {
        console.error("Errore updating the bill", result.error);
        success = false;
        showToast({
          message: "Errore nell'aggiornamento del pagamento del conto",
        });
      }
    }

    if (newOrderItemsToAddToOrder.length) {
      const result = await newOrder({
        tableName: props.table,
        orderItems: newOrderItemsToAddToOrder,
        orderState: "TO_PREPARE",
        paid: false,
        toPrint: true,
      });
      if (result.ok === false) {
        console.error("Errore updating the bill", result.error);
        success = false;
        showToast({
          message: "Errore nelle creazione di un nuovo ordine",
        });
      }
    }

    if (success) {
      props.newOrder?.setOrderItemsMap(reconcile({}));
      showToast({ message: "Conto aggiornato" });

      if (props.afterVariation) {
        props.afterVariation();
      }
    }
  });
  const changeOrders = useAction(changeOrdersAction);
  const changingOrders = useSubmission(changeOrdersAction);

  const setOrderPayedAction = action(
    async (): Promise<Result<void, string>> => {
      const result = await updateOrderProduct(
        props.orders.reduce<{ id: string; quantity: number }[]>(
          (ordersProducts, order) => {
            return ordersProducts.concat(
              order.items.map((item) => ({
                id: item.id,
                quantity: item.quantity,
              })),
            );
          },
          [],
        ),
      );
      if (result?.error) {
        console.error("Error updating the bill", result.error);
        return { ok: false, error: result.error.message };
      } else {
        if (props.afterVariation) {
          props.afterVariation();
        }
        return { ok: true };
      }
    },
  );

  const setOrderPayed = useAction(setOrderPayedAction);
  const changingOrdersAllPayment = useSubmission(setOrderPayedAction);

  return (
    <>
      <div data-testid="bill" class={styles.bill}>
        <Show when={showHeader}>
          <h2 class={styles.title}>
            <span>Conto di {props.table}</span>
            <span>{total().toFixed(2)}€</span>
          </h2>
        </Show>
        <div
          classList={{
            [styles.orders]: true,
          }}
        >
          <For
            each={billOrder}
            fallback={
              <Show
                when={
                  props.newOrder == null ||
                  Object.values(props.newOrder.orderItemsMap).length === 0
                }
              >
                <div class={styles.noOrder}>Non ci sono ordini</div>
              </Show>
            }
          >
            {(order, orderIndex) => (
              <div class={styles.order}>
                <div class={styles.orderInfo}>
                  <div class={styles.sender}></div>
                  <div>
                    {DateTime.fromISO(order.created_at).toFormat(
                      "dd/MM/yyyy - HH:mm",
                    )}
                  </div>
                </div>
                <For each={order.items}>
                  {(orderItem, orderItemIndex) => (
                    <OrderItemComponent
                      item={orderItem}
                      initialQuantity={
                        orderItemsInitialQuantity()[orderItem.id]
                      }
                      onQuantityChanged={(newQuantity) => {
                        setBillOrder(orderIndex(), "items", orderItemIndex(), {
                          ...orderItem,
                          quantity: newQuantity,
                        });
                      }}
                    />
                  )}
                </For>
              </div>
            )}
          </For>
          <Show
            when={
              props.newOrder != null &&
              Object.values(props.newOrder.orderItemsMap).length > 0
            }
          >
            <div class={styles.order}>
              <div class={styles.orderInfo}>
                <div class={styles.sender}></div>
                <div>Nuovo ordine</div>
              </div>
              <For each={Object.values(props.newOrder!.orderItemsMap)}>
                {(orderItem, orderItemIndex) => (
                  <OrderItemComponent
                    item={orderItem}
                    initialQuantity={orderItemsInitialQuantity()[orderItem.id]}
                    onQuantityChanged={(newQuantity) => {
                      props.newOrder?.setOrderItemsMap(
                        orderItem.id,
                        "quantity",
                        newQuantity,
                      );
                    }}
                  />
                )}
              </For>
            </div>
          </Show>
        </div>
        <Show when={orderItemsAreModified()}>
          <div class={styles.actions}>
            <Button
              disabled={
                changingOrders.pending || changingOrdersAllPayment.pending
              }
              label="confirm variations"
              variant="primary"
              onClick={changeOrders}
            >
              <span class={styles.actionButtonText}>Conferma variazioni</span>
            </Button>
          </div>
        </Show>
        <div class={styles.total}>
          <div class={styles.center}>
            {total().toFixed(2).replace(".", ",")} €
          </div>
          <div class={styles.right}>
            <Button
              label="pay entire order"
              variant="icon"
              onClick={() => setShowConfirmPayment(true)}
            >
              <BsCashCoin />
            </Button>
          </div>
        </div>
      </div>
      <AlertDialog
        open={showConfirmPayment()}
        setOpen={setShowConfirmPayment}
        title="Conferma pagamento"
        description="Confermare il pagamento?"
        showCancelButton={true}
        onCancel={() => {
          setShowConfirmPayment(false);
        }}
        onConfirm={async () => {
          const result = await setOrderPayed();
          setShowConfirmPayment(false);
          if (result?.ok) {
            showToast({ message: "Ordine pagato" });
          } else {
            showToast({ message: "Errore nell'aggiornamento del conto" });
          }
        }}
      />
    </>
  );
};

export default Bill;
