import { Dialog } from "@kobalte/core";
import { VsChromeClose } from "solid-icons/vs";
import { Component, JSX } from "solid-js";
import styles from "./Dialog.module.css";

const CustomDialog: Component<{
  open: boolean;
  title?: string;
  variant?: "default" | "small" | "left";
  setOpen: (val: boolean) => void;
  children: JSX.Element;
}> = (props) => {
  return (
    <Dialog.Root open={props.open} onOpenChange={props.setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay class={styles.overlay} />
        <div
          classList={{
            [styles.positioner]: true,
            [styles.small]: props.variant === "small",
            [styles.left]: props.variant === "left",
          }}
        >
          <Dialog.Content
            classList={{
              [styles.content]: true,
              [styles.small]: props.variant === "small",
              [styles.left]: props.variant === "left",
            }}
          >
            <div class={styles.header}>
              <Dialog.Title class={styles.center}>{props.title}</Dialog.Title>
              <div class={styles.right}>
                <Dialog.CloseButton class={styles.closeButton}>
                  <VsChromeClose />
                </Dialog.CloseButton>
              </div>
            </div>
            <Dialog.Description class={styles.description}>
              {props.children}
            </Dialog.Description>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default CustomDialog;
