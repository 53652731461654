import { Component, createSignal, For, Show, onCleanup } from "solid-js";
import Dialog from "~/components/Dialog";
import Input from "~/components/Input";
import { AddButton } from "~/components/Button";
import {
  Variant,
  type Product as ProductT,
  AddProductToOrderFn,
} from "~/common-types";
import styles from "./ProductDialog.module.css";
import InputRadio from "../InputRadio";
import { createEffect } from "solid-js";
import Checkbox from "./Checkbox";
import { createStore } from "solid-js/store";
import SelectQuantity from "~/components/SelectQuantity";

type VariantStore = {
  [id: string]: Variant & { price: number; checked: boolean };
};

const ProductDialog: Component<{
  showSpecs: boolean;
  setShowSpecs: (v: boolean) => void;
  product: ProductT;
  onAdd: AddProductToOrderFn;
  variant?: "left";
}> = (props) => {
  const [selectedVessel, setSelectedVessel] = createSignal<string>("");
  const [otherSpecs, setOtherSpecs] = createSignal<string>("");
  const [variants, setVariants] = createStore<VariantStore>({});
  const [tempPrice, setTempPrice] = createSignal<string>("0.00");
  const [price, setPrice] = createSignal<number>(0);
  const [quantity, setQuantity] = createSignal<number>(1); // Definisci lo stato per la quantità

  const resetState = () => {
    const defaultVessel = props.product.vessels_products.find(
      (vp) => vp.is_default,
    );
    setSelectedVessel(defaultVessel ? defaultVessel.vessels!.id : "");
    setOtherSpecs("");
    setVariants(
      props.product.variants_products.reduce<VariantStore>((acc, vp) => {
        acc[vp.variants!.id] = {
          ...vp.variants!,
          price: vp.price ?? 0,
          checked: false,
        };
        return acc;
      }, {}),
    );
    const initialPrice = defaultVessel?.price || 0;
    setTempPrice(initialPrice.toFixed(2));
    setPrice(initialPrice);
    setQuantity(1);
  };

  createEffect(() => {
    if (props.showSpecs) {
      resetState();
    }
  });

  onCleanup(() => {
    resetState();
  });

  createEffect(function setPriceOnVesselVariantChange() {
    const vessel = props.product.vessels_products.find(
      (vp) => vp.vessels?.id === selectedVessel(),
    );
    const variantsSum = Object.values(variants).reduce<number>((acc, v) => {
      if (v.checked) {
        return acc + v.price;
      }
      return acc;
    }, 0);
    if (vessel) {
      setPrice(vessel.price!);
    }
    if (variantsSum) {
      setPrice((p) => p + variantsSum);
    }
  });

  createEffect(function resetTempPriceOnPriceChange() {
    setTempPrice(price().toFixed(2));
  });

  return (
    <Dialog
      open={props.showSpecs}
      setOpen={props.setShowSpecs}
      title={props.product.name}
      variant={props.variant}
    >
      <div class={styles.productDialog}>
        <SelectQuantity initialQta={1} onChange={setQuantity} />{" "}
        {/* Aggiungi il nuovo componente */}
        <div class={styles.specs}>
          <div class={styles.vesselsVariants}>
            <Show when={props.product.vessels_products.length >= 1}>
              <InputRadio
                label="Formati"
                options={props.product.vessels_products
                  .map((vp) => ({
                    label: `${vp.vessels!.name} (${vp.price!.toFixed(2)}€)`,
                    value: vp.vessels!.id,
                    order_id: vp.vessels!.order_id ?? 0,
                    idDefault: vp.is_default,
                  }))
                  .sort((a, b) => {
                    if (a.idDefault) {
                      return -1;
                    } else if (b.idDefault) {
                      return 1;
                    } else {
                      return a.label.localeCompare(b.label);
                    }
                  })}
                value={selectedVessel()}
                onChange={setSelectedVessel}
              />
            </Show>
            <Show when={props.product.variants_products.length >= 1}>
              <div class={styles.variants}>
                <p class={styles.variantsTitle}>Specifiche</p>
                <For
                  each={Object.values(variants).sort(
                    (a, b) => (a.order_id ?? 0) - (b.order_id ?? 0),
                  )}
                >
                  {(variant) => (
                    <Checkbox
                      label={`${variant.name} (+ ${variant.price!.toFixed(
                        2,
                      )}€)`}
                      checked={variant.checked}
                      onChange={(checked) => {
                        setVariants(variant.id, "checked", checked);
                      }}
                    />
                  )}
                </For>
              </div>
            </Show>
          </div>
          <div>
            <Input
              label=""
              type="text"
              placeholder="Inserisci un commento..."
              value={otherSpecs()}
              onInput={setOtherSpecs}
            />
          </div>
          <div>
            <Input
              label="Prezzo Finale"
              type="number"
              step={0.5}
              min={0}
              value={tempPrice()}
              selectOnFocus={true}
              onInput={setTempPrice}
              onChange={() => {
                const value = parseFloat(tempPrice());
                if (isNaN(value)) {
                  setTempPrice(
                    (
                      props.product.vessels_products.find((vp) => vp.is_default)
                        ?.price || 0
                    ).toFixed(2),
                  );
                  return;
                }
                setPrice(parseFloat(tempPrice()));
              }}
            />
          </div>
        </div>
        <AddButton
          onClick={() => {
            const vessel = props.product.vessels_products.find(
              (vp) => vp.vessels?.id === selectedVessel(),
            );
            const addPayload = {
              id: props.product.id,
              name: props.product.name,
              short_name: props.product.short_name,
              vessel: vessel?.vessels ? vessel.vessels.name : "",
              vesselPrice: vessel?.price ?? 0,
              variants: Object.values(variants)
                .filter((v) => v.checked)
                .map((v) => v.name),
              variantsWithPrice: Object.values(variants)
                .filter((v) => v.checked)
                .map((v) => ({
                  name: v.name,
                  price: v.price, // Aggiungi il prezzo della variante
                })),
              otherSpecs: otherSpecs(),
              price: price(),
              quantity: quantity(),
            };

            props.onAdd(addPayload);
          }}
        />
        <Show when={props.product.details}>
          <div>
            <div class={styles.detailsTitle}>Dettagli prodotto:</div>
            <div class={styles.details}>{props.product.details}</div>
          </div>
        </Show>
      </div>
    </Dialog>
  );
};

export default ProductDialog;
