import { retry } from "~/backoff";
import { createClient } from "~/supabase";

export const getCourses = retry(async () => {
  console.log("API: getCourses");
  const supabase = createClient();
  const productsRes = await supabase
    .from("courses")
    .select("*")
    .order("order_id");
  if (productsRes.error) {
    console.error("error fetching courses", productsRes.error?.message);
    throw new Error("error fetching courses");
  } else {
    return productsRes.data;
  }
}, 3);
