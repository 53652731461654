import { Component } from "solid-js";
import styles from "./Input.module.css";
import * as TextField from "@kobalte/core/text-field";

const Input: Component<
  {
    label: string;
    value?: string;
    placeholder?: string;
    onInput: (v: string) => void;
    onChange?: (v: string) => void;
    selectOnFocus?: boolean;
  } & (
    | {
        type: "number";
        step: number;
        min: number;
      }
    | {
        type: "text";
      }
  )
> = (props) => {
  return (
    <TextField.Root class={styles.textField}>
      <TextField.Label class={styles.label}>{props.label}</TextField.Label>
      <TextField.Input
        class={styles.input}
        placeholder={props.placeholder}
        inputMode={props.type === "number" ? "decimal" : undefined}
        step={props.type === "number" ? props.step : undefined}
        min={props.type === "number" ? props.min : undefined}
        value={props.value}
        onInput={(e) => props.onInput(e.currentTarget.value)}
        onChange={(e: any) => props.onChange?.(e.currentTarget.value)}
        onFocus={(e: any) => props.selectOnFocus && e.currentTarget.select()}
      />
    </TextField.Root>
  );
};

export default Input;
