import { Component, createSignal } from "solid-js";
import styles from "./SelectQuantity.module.css";
import IconButton from "~/components/IconButton";
import { IoAddCircle, IoRemoveCircle } from "solid-icons/io";

const SelectQuantity: Component<{
  initialQta: number;
  onChange: (qta: number) => void;
}> = (props) => {
  const [qta, setQta] = createSignal(props.initialQta);

  const increment = () => {
    setQta((prevQta) => {
      const newQta = prevQta + 1;
      props.onChange(newQta);
      return newQta;
    });
  };

  const decrement = () => {
    setQta((prevQta) => {
      const newQta = Math.max(prevQta - 1, 1); // Impedisce che la quantità scenda sotto 1
      props.onChange(newQta);
      return newQta;
    });
  };

  return (
    <div class={styles.selectQuantityContainer}>
      <label class={styles.label}>Quantità:</label>
      <div class={styles.selectQuantity}>
        <IconButton class={styles.button} onClick={decrement}>
          <IoRemoveCircle size={40} class={styles.buttonIcon} />
        </IconButton>
        <span class={styles.quantity}>{qta()}</span>
        <IconButton class={styles.button} onClick={increment}>
          <IoAddCircle size={40} class={styles.buttonIcon} />
        </IconButton>
      </div>
    </div>
  );
};

export default SelectQuantity;
