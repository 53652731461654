import { RadioGroup } from "@kobalte/core";
import { For } from "solid-js";
import styles from "./InputRadio.module.css";

export default function InputRadio(props: {
  label: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <RadioGroup.Root
      class={styles.radioGroup}
      value={props.value}
      onChange={props.onChange}
    >
      <RadioGroup.Label class={styles.radioGroup__label}>
        {props.label}
      </RadioGroup.Label>
      <div class={styles.radioGroup__items}>
        <For each={props.options}>
          {(option) => (
            <RadioGroup.Item value={option.value} class={styles.radio}>
              <RadioGroup.ItemInput class={styles.radio__input} />
              <RadioGroup.ItemControl class={styles.radio__control}>
                <RadioGroup.ItemIndicator class={styles.radio__indicator} />
              </RadioGroup.ItemControl>
              <RadioGroup.ItemLabel class={styles.radio__label}>
                {option.label}
              </RadioGroup.ItemLabel>
            </RadioGroup.Item>
          )}
        </For>
      </div>
    </RadioGroup.Root>
  );
}
