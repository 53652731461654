import { Component, Show } from "solid-js";
import * as KoTextField from "@kobalte/core/text-field";
import { VsChromeClose } from "solid-icons/vs";
import styles from "./Search.module.css";
import IconButton from "./IconButton";

const Search: Component<{ value: string; onInput: (value: string) => void }> = (
  props,
) => {
  return (
    <KoTextField.Root class={styles.component}>
      <KoTextField.Input
        class={styles.input}
        type="search"
        placeholder="Find Product"
        value={props.value}
        onInput={(e) => props.onInput(e.currentTarget.value)}
      />
      <Show when={props.value}>
        <IconButton
          class={styles.clearButton}
          onClick={() => props.onInput("")}
        >
          <VsChromeClose />
        </IconButton>
      </Show>
    </KoTextField.Root>
  );
};

export default Search;
