import "solid-js";
import { Database } from "./supabase-types";

declare module "solid-js" {
  namespace JSX {
    interface Directives {
      draggable: boolean;
      droppable: boolean;
      sortable: boolean;
    }
  }
}

type Success<Value> = {
  ok: true;
  value: Value;
};

type SuccessVoid = {
  ok: true;
};

type Failure<Error> = {
  ok: false;
  error: Error;
};

export type Result<Value = void, Error = string> = Value extends void
  ? SuccessVoid | Failure<Error>
  : Success<Value> | Failure<Error>;

export type OrderItem = {
  id: string;
  product_id: string;
  description: string;
  price: number;
  quantity: number;
  payed_quantity: number;
  course_id?: string;
};

export type OrderItemsMap = {
  [id: string]: OrderItem;
};

export type Order = {
  order_id: string;
  created_at: string;
  table_name: string;
  state: Database["public"]["Enums"]["order_status"];
  items: OrderItem[];
};

export type OrderToPrint = {
  order_id: string;
  area_name: string;
  table_name: string;
  user_name: string | undefined;
  created_at: Date;
  products: {
    description: string;
    quantity: number;
    price: number;
    course_name?: string;
    course_order?: number;
  }[];
};

export type OrdersToPrint = {
  [printerCycleId: string]: { [orderId: string]: OrderToPrint };
};

export type Category = Database["public"]["Tables"]["categories"]["Row"];
export type Tag = Database["public"]["Tables"]["tags"]["Row"];
export type Vessel = Database["public"]["Tables"]["vessels"]["Row"];
export type Variant = Database["public"]["Tables"]["variants"]["Row"];

export type Product = Database["public"]["Tables"]["products"]["Row"] & {
  tags_products: {
    tags: Tag | null;
  }[];
  vessels_products: {
    is_default: boolean;
    price: number | null;
    vessels: Vessel | null;
  }[];
  variants_products: {
    price: number | null;
    variants: Variant | null;
  }[];
  categories_products: {
    order_id: number | null; // order in which the category should be displayed
    categories: Category | null;
  }[];
};

export type Table = {
  id: string;
  name: string;
  area: string;
  area_order: number;
  order_ids: string[];
  total: number;
};

export type TableWithNumber = Table & { number: string; fullName?: string };

// TODO move these enums inside the database
export enum ViewMode {
  Grid,
  List,
}

export enum TagSelectionMode {
  Single,
  MultiOR,
  MultiAND,
}

export type OrderState = Exclude<
  Database["public"]["Enums"]["order_status"],
  "PAYED"
>;

type VariantPrice = {
  name: string;
  price: number;
};

type ProductIdInOrder = {
  id: string;
  name: string;
  short_name?: string | null;
  vessel: string;
  vesselPrice?: number;
  variants: string[];
  variantsWithPrice?: VariantPrice[];
  otherSpecs: string | undefined;
  price: number;
  course?: string;
  quantity?: number;
};

export const makeOrderItemDescription = (params: ProductIdInOrder) => {
  // Funzione per formattare il prezzo
  const formatPrice = (price: number) =>
    price.toLocaleString("it-IT", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }) + " €";

  const description = [
    params.short_name ? params.short_name : params.name,
    `${params.vessel} (${
      params.vesselPrice
        ? formatPrice(params.vesselPrice)
        : formatPrice(params.price)
    })`,
    params.variantsWithPrice
      ? params.variantsWithPrice
          .map((variant) =>
            variant.price === 0
              ? `${variant.name}`
              : `${variant.name} (${variant.price > 0 ? "+" : ""}${formatPrice(
                  variant.price,
                )})`,
          )
          .join(", ")
      : params.variants,
    params.otherSpecs ? `'${params.otherSpecs}'` : params.otherSpecs,
  ]
    .filter(Boolean)
    .join("\n");

  return description;
};

export const makeOrderItemId = (params: ProductIdInOrder) => {
  const uuid =
    params.id +
    makeOrderItemDescription(params) +
    params.price +
    (params.course ?? "");
  return uuid;
};

export type AddProductToOrderFn = (params: ProductIdInOrder) => void;

export type RemoveProductFromOrderFn = (params: ProductIdInOrder) => void;

export type CompanyData = {
  show_payed_button_on_sender: boolean;
  show_temporary_notification_popups_on_sender: boolean;
};

export type Course = Database["public"]["Tables"]["courses"]["Row"];

export function totalFromOrders(orders: Order[]): number {
  return orders.reduce((acc, order) => {
    return (
      acc +
      order.items.reduce((acc, item) => {
        return acc + item.price * (item.quantity - item.payed_quantity);
      }, 0)
    );
  }, 0);
}
