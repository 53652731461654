import { Component, Show } from "solid-js";
import { AlertDialog } from "@kobalte/core";
import { VsChromeClose } from "solid-icons/vs";
import styles from "./AlertDialog.module.css";
import Button from "./Button";
import { JSXElement } from "solid-js";

const CustomAlertDialog: Component<{
  open: boolean;
  setOpen: (val: boolean) => void;
  title: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  showCancelButton: boolean;
  children?: JSXElement;
}> = (props) => {
  return (
    <AlertDialog.Root
      open={props.open}
      onOpenChange={props.setOpen}
      modal
      preventScroll
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay class={styles.alertDialogOverlay} />
        <div class={styles.alertDialogPositioner}>
          <AlertDialog.Content class={styles.alertDialogContent}>
            <div class={styles.alertDialogHeader}>
              <AlertDialog.Title class={styles.alertDialogTitle}>
                {props.title}
              </AlertDialog.Title>
              <AlertDialog.CloseButton class={styles.alertDialogCloseButton}>
                <VsChromeClose />
              </AlertDialog.CloseButton>
            </div>
            <AlertDialog.Description class={styles.alertDialogDescription}>
              {props.description ?? props.children}
              <Show when={props.showCancelButton}>
                <section class={styles.alertDialogButtons}>
                  <Button
                    variant="default"
                    size="large"
                    onClick={props.onCancel}
                  >
                    No
                  </Button>
                  <Button
                    variant="primary"
                    size="large"
                    onClick={props.onConfirm}
                  >
                    Si
                  </Button>
                </section>
              </Show>
              <Show when={!props.showCancelButton}>
                <section class={styles.alertDialogButtons}>
                  <Button
                    role="button"
                    label="confirm"
                    variant="primary"
                    size="large"
                    onClick={props.onConfirm}
                  >
                    Conferma
                  </Button>
                </section>
              </Show>
            </AlertDialog.Description>
          </AlertDialog.Content>
        </div>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default CustomAlertDialog;
