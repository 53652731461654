import {
  IoAddCircle,
  IoCameraOutline,
  IoRemoveCircle,
  IoTrashBin,
} from "solid-icons/io";
import { createMemo, createSignal, Show } from "solid-js";
import {
  AddProductToOrderFn,
  RemoveProductFromOrderFn,
  type Product as ProductT,
} from "~/common-types";
import IconButton from "~/components/IconButton";
import ProductDialog from "./ProductDialog";
import styles from "./Product.module.css";

export default function Product(props: {
  product: ProductT;
  count: number;
  onAddToOrder: AddProductToOrderFn;
  onRemoveFromOrder: RemoveProductFromOrderFn;
  variant?: "left";
}) {
  const [showSpecs, setShowSpecs] = createSignal(false);
  const [showProductImage, setShowProductImage] = createSignal(true);
  const defaultVessel = createMemo(() =>
    props.product.vessels_products.find((vp) => vp.is_default),
  );

  return (
    <>
      <ProductDialog
        showSpecs={showSpecs()}
        setShowSpecs={setShowSpecs}
        product={props.product}
        onAdd={(params) => {
          props.onAddToOrder(params);
          setShowSpecs(false);
        }}
        variant={props.variant}
      />
      <div
        data-testid="product"
        class={styles.product}
        onClick={() => setShowSpecs(true)}
      >
        <div class={styles.imageContainer}>
          <Show
            when={props.product.imageURL && showProductImage()}
            fallback={<IoCameraOutline size={70} />}
          >
            <img
              src={props.product.imageURL!}
              onerror={() => setShowProductImage(false)}
              alt={`image of ${props.product.name}`}
            />
          </Show>
        </div>
        <div class={styles.details}>
          <div class={styles.detailsFirstRow}>
            <h3 class={styles.productName}>
              {props.product.short_name
                ? props.product.short_name
                : props.product.name}
            </h3>
            <Show when={props.count > 0}>
              <div class={styles.count}>
                <span>{props.count}</span>
              </div>
            </Show>
          </div>
          <div class={styles.detailsSecondRow}>
            <h4 class={styles.price}>
              {defaultVessel()!.price!.toLocaleString("it-IT", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}{" "}
              €
            </h4>
            <div class={styles.actions}>
              <Show when={props.count === 1}>
                <IconButton
                  label="clear from order"
                  onClick={() =>
                    props.onRemoveFromOrder({
                      id: props.product.id,
                      name: props.product.name,
                      short_name: props.product.short_name,
                      vessel: defaultVessel()?.vessels?.name ?? "",
                      variants: [],
                      otherSpecs: undefined,
                      price: defaultVessel()!.price!,
                    })
                  }
                >
                  <IoTrashBin />
                </IconButton>
              </Show>
              <Show when={props.count > 1}>
                <IconButton
                  label="remove from order"
                  onClick={() =>
                    props.onRemoveFromOrder({
                      id: props.product.id,
                      name: props.product.name,
                      short_name: props.product.short_name,
                      vessel: defaultVessel()?.vessels?.name ?? "",
                      variants: [],
                      otherSpecs: undefined,
                      price: defaultVessel()!.price!,
                    })
                  }
                >
                  <IoRemoveCircle />
                </IconButton>
              </Show>
              <IconButton
                label="add to order"
                onClick={() => {
                  props.onAddToOrder({
                    id: props.product.id,
                    name: props.product.name,
                    short_name: props.product.short_name,
                    vessel: defaultVessel()?.vessels?.name ?? "",
                    variants: [],
                    otherSpecs: undefined,
                    price: defaultVessel()!.price!,
                  });
                }}
              >
                <IoAddCircle />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
