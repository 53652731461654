import { Component } from "solid-js";
import { Checkbox as KBCheckbox } from "@kobalte/core";
import { FaSolidCheck } from "solid-icons/fa";
import styles from "./Checkbox.module.css";

const Checkbox: Component<{
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}> = (props) => {
  return (
    <KBCheckbox.Root
      class={styles.checkbox}
      checked={props.checked}
      onChange={props.onChange}
    >
      <KBCheckbox.Input class={styles.checkbox__input} />
      <KBCheckbox.Control class={styles.checkbox__control}>
        <KBCheckbox.Indicator class={styles.checkbox__indicator}>
          <FaSolidCheck />
        </KBCheckbox.Indicator>
      </KBCheckbox.Control>
      <KBCheckbox.Label class={styles.checkbox__label}>
        {props.label}
      </KBCheckbox.Label>
    </KBCheckbox.Root>
  );
};

export default Checkbox;
