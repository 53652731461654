import { retry } from "~/backoff";
import { createClient } from "~/supabase";

export const getProducts = retry(async () => {
  console.log("API: getProducts");
  const supabase = createClient();
  const productsRes = await supabase
    .from("products")
    .select(
      "*, vessels_products(is_default, price, vessels(*)), variants_products(price, variants(*)), tags_products(tags(*)), categories_products(order_id, categories(*))",
    )
    .eq("canceled", false)
    .eq("available", true)
    .order("name");
  if (productsRes.error) {
    console.error("error fetching products", productsRes.error?.message);
    throw new Error("error fetching products");
  } else {
    return productsRes.data;
  }
}, 3);
