import { Component, For } from "solid-js";
import styles from "./ToggleGroup.module.css";

type ToggleGroupProps = {
  items: {
    id: string;
    name: string;
  }[];
  onItemSelected: (id: string) => void;
  selectedItem?: string;
};

const ToggleGroup: Component<ToggleGroupProps> = (props) => {
  return (
    <section class={styles.container}>
      <For each={props.items}>
        {(item) => (
          <button
            classList={{
              [styles.item]: true,
              [styles.item_selected]: props.selectedItem === item.id,
            }}
            onClick={() => props.onItemSelected(item.id)}
          >
            {item.name}
          </button>
        )}
      </For>
    </section>
  );
};

export default ToggleGroup;
